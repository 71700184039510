<template>
  <!-- 教练团队 -->
  <div class="jy_aboutuser">
    <div class="jy_aboutuser_bg">
      <img :src="region_bg" alt="" style="width:100%" />
    </div>
    <div class="jy_aboutuser_nav">
      <div class="col_ys">
        <h2>{{ jjData.jj_title }}</h2>
        <div class="col_html" v-html="jjData.jj_html"></div>
      </div>
      <div class="col_ys">
        <h2>{{ ysData.ys_title }}</h2>
        <div class="col_html" v-html="ysData.ys_html"></div>
      </div>
      <div class="col_ys">
        <h2>{{ lxData.lx_title }}</h2>
        <div class="col_html" v-html="lxData.lx_html"></div>
      </div>
      <div class="col_ys">
        <h2>{{ lxData.zx_ly }}</h2>
        <div class="current-hzyx">
          <div class="current-hzyx-left">
            <el-input
              placeholder="请输入您的姓名"
              v-model="formData.username"
              clearable
            >
            </el-input>
            <el-input
              placeholder="请输入您的手机号"
              v-model="formData.phone"
              clearable
            >
            </el-input>
            <el-input
              placeholder="请输入您的E-mail"
              v-model="formData.email"
              clearable
            >
            </el-input>
            <el-input
              placeholder="请输入您所需要的服务"
              v-model="formData.company"
              clearable
            >
            </el-input>
          </div>
          <div class="current-hzyx-right">
            <el-input
              type="textarea"
              :placeholder="lxData.lxyyText"
              v-model="formData.msgcontent"
              maxlength="1000"
              :autosize="{ minRows: 10, maxRows: 10 }"
              show-word-limit
              style="resize:none;"
            >
            </el-input>
            <el-button type="primary" @click="submitTo">提交预约</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      region_bg: require('@/assets/image/all/jy_bg.png'),
      ysData: {},
      jjData: {},
      lxData: {},
      formData: {
        company: '',
        phone: '',
        username: '',
        email: '',
        msgcontent: ''
      }
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.ysData = res.data.data.优势
          that.jjData = res.data.data.简介
          that.lxData = res.data.data.联系
        }
      })
    },
    submitTo () {
      submitToData(this.formData).then(res => {
        if (res.data.code == 1) {
          this.$message.success('留言成功')
          let obj = {
            company: '',
            phone: '',
            username: '',
            email: '',
            msgcontent: ''
          }
          this.formData = obj
          this.getData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.jy_aboutuser {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .jy_aboutuser_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
      animation: scaleAnim 3s ease forwards;
    }
  }
  .jy_aboutuser_nav {
    margin: 1rem auto;
    width: 15rem;
    text-align: center;
    .col_ys {
      line-height: 2;
      margin-top: 0.5rem;
      h2 {
        font-size: 0.4rem;
        margin-bottom: 0.3rem;
      }
      .col_html {
        background-color: #fff;
        padding: 0.5rem;
      }
      .current-hzyx {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .current-hzyx-left {
          width: 49%;
          .el-input {
            margin-bottom: 31px;
            /deep/input {
              padding: 30px;
              padding-left: 20px;
            }
          }
        }
        .current-hzyx-right {
          width: 49%;
          .el-textarea {
            /deep/textarea {
              padding: 20px;
            }
          }
          .el-button {
            width: 100%;
            margin-top: 33px;
            padding: 20px 0;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
